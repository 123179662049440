<template>

  <b-container class="mt-1">
    <div v-if="!contract_selected && contracts">
      <p>Selecteer een lening om een mutatie in te boeken</p>
      <div>
        <b-table id="table-select-contract" class="mt-2 table-sm" thead-class="d-none" responsive borderless sticky-header="150px" small hover :items="contracts.filter(e => e.type.indexOf('LoanContract') >= 0)" :fields="contractTable.fields" :tbody-tr-class="rowClass" :tbody-class="[!contract_selected || contract_selected.id === null ? 'not-selected' : 'selected']">
          <template #cell(stakeholders)="row">
            <b-avatar-group>
              <b-avatar variant="light-primary" v-b-tooltip.hover :title="receiver.fullname" class="pull-up" v-for="(receiver, index) in contracts[row.index].receivers" :key="'receiver' + index" :src="receiver.image" :text="!receiver.image ? receiver.initials : null"></b-avatar>
              <b-avatar variant="light-primary" v-b-tooltip.hover :title="sender.fullname" class="pull-up" v-for="(sender, index) in contracts[row.index].senders" :key="'sender' + index" :src="sender.image" :text="!sender.image ? sender.initials : null"></b-avatar>
            </b-avatar-group>
          </template>

          <template #cell(button)="row">
            <b-button pill variant="primary" class="text-nowrap show-when-hovered" @click="selectContract(row.item)">
              Selecteer
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div v-show="contract_selected">
      <b-row v-show="!initialSet">
        <b-col class="text-center">
          <b-button pill variant="primary" class="text-nowrap show-when-hovered" @click="contract_selected = null">
            Selecteer ander contract
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="mutationForm">
        <b-row class="mt-1 justify-content-between">
          <b-col cols="12" lg="5" class="align-self-end">
            <label for="mutation_date">
              Periode van betaling
              <InfoButton id="newRepayment-date-id" text="Selecteer in welke periode de betaling plaatsvond" :bgBlue="true" />
            </label>
            <b-form-group label-for="mutation_date" style="margin-bottom: 0px !important;">
              <validation-provider #default="{ errors }" name="Mutatiedatum" rules="required">
                <div class="d-flex">
                  <b-form-group class="mr-2" style="margin-bottom: 0px !important;">
                    <b-form-select v-model="periodSelected" :options="getPeriodOptions()"></b-form-select>
                  </b-form-group>
                </div>

                <!-- <b-form-datepicker locale="nl" :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" v-model="newMutationDate" :initial-date="newMutationDate" v-bind="datepickerLabel" :min="minDate" :value-as-date="true"></b-form-datepicker> -->
                <!-- <cleave
                    v-model="newMutationDate"
                    :raw="false"
                    :options="maskOptions.date"
                    class="form-control"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4" class=" align-self-end">
            <label>
              Bedrag van bank betaling?
              <InfoButton id="newRepayment-amount-id" text="Vul hier in hoeveel euro is overgemaakt." :bgBlue="true" />
            </label>
            <validation-provider #default="{ errors }" name="bedrag" rules="required|min_value:0">
              <b-input-group prepend="€">
                <cleave class="form-control" placeholder="1.000,00" :value="newMutationAmount" v-model="newMutationAmount" :options="maskOptions.currency" />
              </b-input-group>
              <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="3" class="justify-content-center align-self-lg-end text-right">
            <b-button variant="primary" :disabled="!periodSelectedEndDate || !newMutationAmount" @click="addMutation">Voeg toe</b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-table v-if="contract_selected" class="mt-1" responsive borderless sticky-header="150px" small sort-by="date" :sort-desc="true" :items="contract_selected.contract.bank_mutations" :fields="mutationTable.fields">
        <template #cell(index)="row">
          {{ row.item.hashid }}
        </template>

        <template #cell(action)="row">
          <b-button variant="danger" @click="deleteMutation(row.item.hashid, row.index)">
            Verwijder
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col class="text-center">
          <b-button variant="primary" @click="$bvModal.hide('mutation')">Sluit</b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
// import store from '@/store'
import { BTable, BFormGroup, BRow, BCol, BButton, BAvatarGroup, BAvatar, VBTooltip, BInputGroup, BContainer } from 'bootstrap-vue'
import InfoButton from '@/components/InfoButton.vue'
import datepickerLabel from '@/components/datepicker.js'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Cleave,
    BContainer,
    BTable, BRow, BCol, BButton, BInputGroup,
    InfoButton, BAvatarGroup, BAvatar,
    BFormGroup,
  },
  props: {
    contracts: {
      type: Array,
      default: null
    },
    contract: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted() {
    if (this.contract) {
      this.initialSet = true;
      this.selectContract(this.contract);
      this.periodSelected = 0; // Perform the periodSelected logic for the default option
    }
  },
  watch: {
    periodSelected(newVal) {
      if (this.contract && this.contract.contract && this.contract.contract.actual) {
        const selectedPeriod = this.contract.contract.actual[newVal];

        if (selectedPeriod && selectedPeriod.period_date) {
          console.log(this.contract)
          const periodDate = new Date(selectedPeriod.period_date);
          const periodLength = this.contract.contract.payment_period || 1;
          const paymentDay = this.contract.contract.payment_day;
          const newDay = Math.min(paymentDay - 1, new Date(periodDate.getFullYear(), periodDate.getMonth() + periodLength + 1, 0).getDate());

          // 1) Compute candidate date = (periodDate + periodLength months) 
          //    with day = paymentDay
          const newPeriodDate = new Date(
            periodDate.getFullYear(),
            periodDate.getMonth() + periodLength,
            newDay + 1
          );

          this.periodSelectedEndDate = newPeriodDate;
        }
      }
      // if (this.contract && this.contract.contract && this.contract.contract.actual) {
      //   const selectedPeriod = this.contract.contract.actual[newVal];
      //   console.log(this.contract.contract.payment_day)
      //   new Date(year, month, 0).getDate();
      //   if (selectedPeriod && selectedPeriod.period_date) {
      //     const periodDate = new Date(selectedPeriod.period_date);
      //     const periodLength = this.contract.contract.payment_period || 1;

      //     const newPeriodDate = new Date(periodDate);
      //     newPeriodDate.setMonth(newPeriodDate.getMonth() + periodLength);
      //     newPeriodDate.setDate(newPeriodDate.getDate() - 1);

      //     this.periodSelectedEndDate = newPeriodDate;
      //   }
      // }
    },
  },
  data() {
    return {
      cleave: null,
      mutationDateOptions: [],
      datepickerLabel,
      newMutationDate: "",
      newMutationAmount: 0,
      periodSelected: null,
      periodSelectedEndDate: null,
      initialSet: false,
      contract_selected: false,
      deleting: new Map(),
      maskOptions: {
        date: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-",
        },
        currency: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
      contractTable: {
        fields: [
          { key: 'stakeholders', label: '' },
          {
            key: 'start_date', label: '',
            formatter: (value) => {
              return new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(value));
            }
          },
          {
            key: 'total',
            label: '',
            formatter: (value) => {
              return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
            },
          },
          { key: 'button', label: '', tdClass: 'text-right' },
        ]
      },
      mutationTable: {
        fields: [
          // { key: 'index', label: '#'},
          {
            key: 'date', label: 'Datum',
            formatter: (value) => {
              return new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(value));
            }
          },
          {
            key: 'amount', label: 'Bankbetaling',
            formatter: (value) => {
              return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
            },
          },
          { key: 'action', label: 'Actie' },
        ],
      },
    }
  },
  computed: {
    minDate() {
      return new Date(this.contract_selected.start_date)
    },
    // maxDate() {
    //   let date = new Date();
    //   let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    //   console.log("lastday",  lastDay.toISOString().split('T')[0])
    //   return lastDay.toISOString().split('T')[0]
    // }
  },
  methods: {
    getPeriodOptions() {
      const actuals = this.contract.contract.actual;
      const periodOptions = actuals.map((actual, index) => {
        const startDate = new Date(actual.period_date);
        const endDate = index < actuals.length - 1 ? new Date(new Date(actuals[index + 1].period_date)) : null;
        if (endDate) endDate.setDate(Math.min(this.contract.contract.payment_day, endDate.getDate()));
        const periodText = endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : `${startDate.toLocaleDateString()} - Einde`;
        return { value: index, text: `Periode ${index + 1} | ${periodText}` };
      });
      return periodOptions;
    },
    addMutation() {
      let _this = this;

      this.$refs.mutationForm.validate().then(success => {
        if (!success) {
          return false
        }
        console.log(this.contract_selected);
        this.$http.post("/bankmutation", {
          date: this.periodSelectedEndDate.toISOString().split('T')[0],
          amount: this.newMutationAmount,
          hashid: this.contract_selected.contract.hashid
        }).then((response) => {
          _this.contract_selected.contract.bank_mutations.push(response.data.mutation);
          _this.$emit("added", response.data.mutation);
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Bankmutatie toegevoegd',
              text: "Bankmutatie is toegevoegd, sluit om naar je overzicht te gaan.",
              icon: 'InfoIcon',
              variant: 'primary',
            },
          });
        });
      })
    },
    deleteMutation(_db, index) {
      let _this = this;

      if (this.deleting.get(_db)) return;

      let formData = new FormData();
      formData.append("hashid", _db);
      formData.append("_method", "DELETE");

      this.deleting.set(_db, _db);

      this.$http.post("/bankmutation", formData).then((response) => {
        console.log(response);
        console.log(_this.contract_selected.contract.bank_mutations[index].hashid);
        let array = [];
        _this.contract_selected.contract.bank_mutations.forEach((mut) => {
          if (mut.hashid !== _db) {
            array.push(mut);
          }
        });
        _this.contract_selected.contract.bank_mutations = array;
        _this.$emit("deleted");
      });
    },
    selectContract(contract) {
      this.contract_selected = contract
      // console.log("NEXT PERIOD PAYMENT: ", this.contract_selected.contract.next_period_payment.toFixed(2));
      this.newMutationAmount = this.contract_selected.contract.next_period_payment.toFixed(2);

      // let f = new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
      // this.newMutationDate = new Date(this.contract_selected.contract.next_payment_date);
      // console.log("newMutationDate: ", this.newMutationDate);
      // this.maskOptions.date.dateMin = f.format(new Date(this.contract_selected.start_date)).split("-").reverse().join("-");
    },
    rowClass(item, type) {
      // if no contract has been selected, return no class and end the function
      if (this.contractSelected === null) {
        return ''
      }

      // if a contract has been selected, apply classes to the rows
      // if a contract has been selected, apply class 'active' to that row, otherwise, apply 'opacity' to that row
      if (item && type === 'row') {
        if (item == this.contractSelected) {
          return 'active'
        } else {
          return 'opacity'
        }
      } else {
        return null
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getPeriodsBetweenDates(startDate, endDate, paymentPeriod) {
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      paymentPeriod = parseInt(paymentPeriod)


      let result = []
      let periodNumber = 1;

      if (startDate > endDate) {
        throw "End date must be greated than start date."
      }

      while (startDate < endDate) {
        let beginningOfPeriod = startDate
        let endingOfPeriod = new Date(startDate.setMonth(startDate.getMonth() + paymentPeriod))
        endingOfPeriod = new Date(endingOfPeriod.setDate(endingOfPeriod.getDate() - 1))
        // console.log("endingOfPeriod: ", endingOfPeriod)
        let text = "Periode " + periodNumber + " | " + new Intl.DateTimeFormat('nl-NL', { month: 'long', year: 'numeric' }).format(beginningOfPeriod) + " t/m " + new Intl.DateTimeFormat('nl-NL', { month: 'long', year: 'numeric' }).format(endingOfPeriod)
        console.log(text)
        result.push({
          value: startDate,
          text: text,
          // text: this.capitalizeFirstLetter(new Intl.DateTimeFormat('nl-NL', {month: 'long', year: 'numeric'}).format(date)),
        });
        startDate = new Date(startDate.setMonth(startDate.getMonth() + paymentPeriod))
        periodNumber += 1
      }

      console.log(result)


      return this.mutationDateOptions = result

    }
  }
}
</script>

<style lang="scss" scoped>
#table-select-contract>tbody>tr:not(:hover) .btn {
  visibility: hidden;
}

#table-select-contract>tbody:hover.not-selected>tr:not(:hover) {
  opacity: 0.5;
}

#table-select-contract>tbody:hover.not-selected>tr:not(:hover)>td>button {
  opacity: 1;
}

#table-select-contract>tbody.selected>tr:not(:hover) {
  opacity: 0.5;
}

#table-select-contract>tbody.selected>tr.active {
  opacity: 1 !important;
}

#table-select-contract>tbody.selected>tr.active>td>.btn {
  visibility: visible;
}
</style>
